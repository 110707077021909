import React from 'react';
import {
	Box,
	Button,
	LinearProgress,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import {
	TextField,
  } from 'formik-mui';
import Layout from '../layout';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import {
	doc,
	getDoc,
} from "firebase/firestore";
import { db } from '../../../firebase-info';
import useLocalStorageState from 'use-local-storage-state'
import { navigate } from "gatsby";
import { GamesContext, AuthContext } from '../../contexts/contexts.js';

const formContainer = {
	width: '100%',
	marginTop: '32px',
};
const inputWidth = {
	minWidth: '80%'
};

const RavenGameForm = ({handleOnChange }) => {
	const { t } = useTranslation();
    const gamesInfo = React.useContext(GamesContext);
    const userInfo = React.useContext(AuthContext);
    const [experiment, setExperiment] = useLocalStorageState("experiment");
    const [gamePosition, setGamePosition] = useLocalStorageState("gamePosition", {defaultValue: undefined});

	const handleSubmit = async (values) => {
		try {
            if (gamePosition !== undefined) {
                const game = {...experiment.games[gamePosition], params: {...values}};

                experiment.games[gamePosition] = game;
            } else {
                const gameData = gamesInfo.filter((game) => game.id === "raven");
                const game = {...gameData[0], params: {...values}};

                const gamesLikeThis = experiment.games.filter((g) => (game.id === g.id))
                const maxInstance = Math.max(gamesLikeThis.map((g) => g.instance))
                if(!maxInstance){
                    game.instance = 1;
                }
                else{
                    game.instance = maxInstance+1;
                }
                game["listId"] = `${game.id}_${game.instance}`;
                experiment.games.push(game);
            }
            
            setGamePosition(undefined);
            setExperiment(experiment);

		} catch(e) {
            console.log(e);
            //we should handle the error
		}
	}

    const goBack = () => {
        if(gamePosition !== undefined){
            navigate("/checkoutExperiment/");
        }
        else{
            navigate(-1);
        }
    }

	return(
        <Layout>
            <Formik
                initialValues={
                    (gamePosition !== undefined && experiment?.games[gamePosition].params) || {
                    time: '',
                }}
                style={formContainer}
                onSubmit={async (values, actions) => { 
                    await handleSubmit(values)
                    actions.setSubmitting = false;
                    goBack();
                }}
            >
                {({submitForm, resetForm, isSubmitting}) => (
                    <Form onChange={handleOnChange}>
                        <Box margin={1}>
                            <Field
                                component={TextField}
                                style={inputWidth}
                                name="time"
                                type="number"
                                label={t("Add time limit (leave empty if no time limit is desired)")}
                            />
                        </Box>
                        {isSubmitting && <LinearProgress />}
                        <Box margin={1}>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={isSubmitting || !userInfo?.userData.gamePermissions.raven.usable}
                                onClick={submitForm}
                            >
                                {gamePosition !== undefined ? 
                                    <Trans>Modify game parameters</Trans>
                                :
                                    <Trans>Add game to experiment</Trans>
                                }
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    resetForm();
                                }}
                            >
                                <Trans>Reset values</Trans>
                            </Button>
                            <Button
                                sx={{margin: 1}}
                                variant="contained"
                                color="error"
                                size="large"
                                disabled={isSubmitting}
                                onClick={() => {
                                    goBack();
                                }}
                            >
                                <Trans>Cancel</Trans>
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Layout>
	);
}

export default RavenGameForm;