import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeRavenForm from '../../components/game-forms/be-raven-form';
import BeGameInstructions from '../../components/be-game-instructions';
import useLocalStorageState from 'use-local-storage-state'

const RavenGamePage = (props) => {
  const [experiment, setExperiment] = useLocalStorageState("experiment");
  const [dataUser, setDataUser] = React.useState();
  const handleOnChange = (event) => {
    dataUser[event.target.name] = event.target.value;
    setDataUser({...dataUser});
    console.log(dataUser);
  };

  React.useEffect(() => {
    setDataUser((props.location.state?.gamePosition !== undefined && experiment?.games[props.location.state.gamePosition].params) || {
        time: '',
      }
    );
  }, []);

	return(
        <GameCreationRoot title={"Raven Game"}>
          <BeGameInstructions gameId={'raven'} dataUser={dataUser}  />
            <BeRavenForm handleOnChange={handleOnChange} />
        </GameCreationRoot>
	);
}

export default RavenGamePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "form_errors"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;